<template>
  <phi-page color="#f2f2f2">
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />

      <h1>{{ post ? post.subject : '...' }}</h1>
    </div>

    <person-post
      class="ui-card ui-padded"
      :person-id="personId"
      :post-id="postId"
      @fetch="onPostFetch"
    ></person-post>
  </phi-page>
</template>

<script>
import PersonPost from '@/modules/communication/components/Person/Post.vue';

export default {
  components: {
    PersonPost,
  },

  data() {
    return {
      personId: this.$route.params.personId,
      groupId: this.$route.params.groupId,
      postId: this.$route.params.postId,

      post: null,
    };
  },

  methods: {
    onPostFetch(post) {
      this.post = post;
    },
  },
};
</script>

<style lang="scss">
.teacher-year-picker {
  border: 0;
  background: transparent;
}

.expanded-toolbar {
  z-index: 3;
}
</style>