<template>
  <div class="communication-person-post">
    <cms-api-page
      :person-id="personId"
      :page-id="postId"
      :data="pageData"
    ></cms-api-page>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';
import apiCommunication from '@/modules/communication/api/index.js';
import CmsApiPage from '@/modules/cms/components/Api/Page.vue';

import { UiItem, UiIcon } from '@/modules/ui/components';

export default {
  name: 'communication-person-post',
  components: { CmsApiPage },
  mixins: [useApi],
  api: apiCommunication,

  props: {
    personId: {
      type: String,
      required: true
    },

    postId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      post: null,
      pageData: {}
    };
  },

  watch: {
    postId: {
      handler() {
        this.fetchPost();
      }
    }
  },

  mounted() {
    this.fetchPost();
  },

  methods: {
    async fetchPost() {
      this.post = await this.$api.getPost(this.postId);
      this.$emit('fetch', this.post);
    }
  }
};
</script>